import React, { useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_6sn3tvn", "template_v0icb4f", form.current, {
        publicKey: "aSOZX4TQNbYy2d01h",
      })
      .then(
        () => {
          toast("We got your message, we'll contact via email within 24hrs 😎");
        },
        (error) => {
          toast("Oops, there was an error while sending your message 🙄");
        }
      );
  };

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Header />

        <section className="relative py-36 bg-gray-50 overflow-hidden">
          <img
            className="absolute bottom-0 right-0"
            src="flaro-assets/images/contact/gradient2.svg"
            alt=""
          />
          <div className="relative z-10 container px-4 mx-auto">
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 p-8">
                <div className="flex flex-col justify-between h-full">
                  <div className="mb-12 md:max-w-md block">
                    <p className="mb-6 text-sm text-yellow-400 font-bold uppercase tracking-px">
                      Say hello
                    </p>
                    <h2 className="text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">
                      Your project is one message away 😍
                    </h2>
                  </div>

                  <div className="block">
                    <p className="mb-4 text-sm text-gray-400 font-bold uppercase tracking-px">
                      Instant messaging
                    </p>
                    <div className="flex justify-center gap-2 flex-wrap w-full">
                      <a
                        target="_blank"
                        href="https://wa.me/message/27AYHNRZWYRKH1"
                        className="lg:w-1/5 p-4 bg-white rounded-xl cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000"
                      >
                        <img src="/images/wp.png" className="w-48"></img>
                        <p className="w-full font-bold leading-normal text-center pt-2">
                          WhatsApp
                        </p>
                      </a>
                      <a
                        target="_blank"
                        href="https://line.me/ti/p/eU960dlCcB"
                        className="lg:w-1/5 p-4 bg-white rounded-xl cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000"
                      >
                        <img src="/images/line.png" className="w-48"></img>
                        <p className="w-full font-bold leading-normal text-center pt-2">
                          Line
                        </p>
                      </a>

                      <a
                        target="_blank"
                        href="https://m.me/goldenpupa"
                        className="lg:w-1/5 p-4 bg-white rounded-xl cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000"
                      >
                        <img src="/images/faceb.png" className="w-48"></img>
                        <p className="w-full font-bold leading-normal text-center pt-2">
                          Messenger
                        </p>
                      </a>

                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/goldenpupa/"
                        className="lg:w-1/5 p-4 bg-white rounded-xl cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000"
                      >
                        <img src="/images/in.png" className="w-48"></img>
                        <p className="w-full font-bold leading-normal text-center pt-2">
                          LinkedIn
                        </p>
                      </a>
                    </div>
                    <p className="my-4 text-sm text-gray-400 font-bold uppercase tracking-px">
                      Email
                    </p>
                    <ul className="mb-14" contentEditable="false">
                      <li
                        className="text-xl font-semibold leading-normal"
                        contentEditable="false"
                      >
                        contact@goldenpupa.com
                      </li>
                      <li
                        className="text-xl font-semibold leading-normal"
                        contentEditable="false"
                      ></li>
                    </ul>
                    <ul></ul>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-8">
                <form
                  className="px-11 pt-8 pb-12 bg-white bg-opacity-80 md:max-w-xl ml-auto rounded-4xl shadow-12xl"
                  ref={form}
                  onSubmit={sendEmail}
                >
                  <label className="block mb-4">
                    <p className="mb-2 text-gray-900 font-semibold leading-normal">
                      Name
                    </p>
                    <div className="relative">
                      <svg
                        className="absolute left-4 top-1/2 transform -translate-y-1/2"
                        width={20}
                        height={26}
                        viewBox="0 0 20 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3327 8.83358C13.3327 10.6745 11.8403 12.1669 9.99935 12.1669C8.1584 12.1669 6.66602 10.6745 6.66602 8.83358C6.66602 6.99263 8.1584 5.50024 9.99935 5.50024C11.8403 5.50024 13.3327 6.99263 13.3327 8.83358Z"
                          stroke="#9CA3AF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99935 14.6669C6.77769 14.6669 4.16602 17.2786 4.16602 20.5002H15.8327C15.8327 17.2786 13.221 14.6669 9.99935 14.6669Z"
                          stroke="#9CA3AF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        className="pl-12 pr-4 py-3 w-full text-gray-500 font-medium placeholder-gray-500 outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                        name="name"
                        type="text"
                        placeholder="First & last name"
                      />
                    </div>
                  </label>
                  <label className="block mb-4">
                    <p className="mb-2 text-gray-900 font-semibold leading-normal">
                      Email Address
                    </p>
                    <div className="relative">
                      <svg
                        className="absolute left-4 top-1/2 transform -translate-y-1/2"
                        width={20}
                        height={26}
                        viewBox="0 0 20 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3327 8.83358C13.3327 10.6745 11.8403 12.1669 9.99935 12.1669C8.1584 12.1669 6.66602 10.6745 6.66602 8.83358C6.66602 6.99263 8.1584 5.50024 9.99935 5.50024C11.8403 5.50024 13.3327 6.99263 13.3327 8.83358Z"
                          stroke="#9CA3AF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99935 14.6669C6.77769 14.6669 4.16602 17.2786 4.16602 20.5002H15.8327C15.8327 17.2786 13.221 14.6669 9.99935 14.6669Z"
                          stroke="#9CA3AF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        className="pl-12 pr-4 py-3 w-full text-gray-500 font-medium placeholder-gray-500 outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Email address"
                      />
                    </div>
                  </label>
                  <label className="block">
                    <p className="mb-2 text-gray-900 font-semibold leading-normal">
                      Message
                    </p>
                    <div className="relative">
                      <svg
                        className="absolute left-4 top-4"
                        width={20}
                        height={26}
                        viewBox="0 0 20 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3327 8.83358C13.3327 10.6745 11.8403 12.1669 9.99935 12.1669C8.1584 12.1669 6.66602 10.6745 6.66602 8.83358C6.66602 6.99263 8.1584 5.50024 9.99935 5.50024C11.8403 5.50024 13.3327 6.99263 13.3327 8.83358Z"
                          stroke="#9CA3AF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99935 14.6669C6.77769 14.6669 4.16602 17.2786 4.16602 20.5002H15.8327C15.8327 17.2786 13.221 14.6669 9.99935 14.6669Z"
                          stroke="#9CA3AF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <textarea
                        className="mb-6 p-4 px-12 w-full h-48 font-medium text-gray-500 outline-none placeholder-gray-500 border border-gray-300 resize-none rounded-lg focus:ring focus:ring-indigo-300"
                        id="message"
                        name="message"
                        placeholder="Write message"
                        defaultValue={""}
                      />
                      <div className="md:inline-block">
                        <button
                          className="py-4 px-9 w-full text-black font-semibold border  rounded-xl shadow-2xl focus:ring focus:ring-black bg-yellow-300 hover:bg-yellow-400 transition ease-in-out duration-200"
                          type="sumbit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </label>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </>
    </React.Fragment>
  );
}
