import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Team() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Header />

        <section className="pt-24 pb-36 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <h2 className="mb-24 text-6xl md:text-8xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none">
              The team behind GOLDENPUPA
            </h2>

            <div className="flex flex-wrap max-w-7xl mx-auto -m-3">
              <div className="w-full md:w-1/2 lg:w-1/5 p-3">
                <div className="relative max-w-max mx-auto h-full overflow-hidden rounded-3xl">
                  <img
                    className="h-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src="flaro-assets/images/team/team.png"
                    alt=""
                  />
                  <div className="absolute bottom-0 left-0 w-full p-2">
                    <div
                      className="bg-black bg-opacity-80 w-full py-4 px-5 rounded-lg"
                      style={{ backdropFilter: "blur(3px)" }}
                    >
                      <h3 className="mb-1 text-lg text-white font-bold leading-snug">
                        Natchaya Dutch
                      </h3>
                      <p className="text-gray-400 font-medium">CEO</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/5 p-3">
                <div className="relative max-w-max mx-auto h-full overflow-hidden rounded-3xl">
                  <img
                    className="h-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src="flaro-assets/images/team/team3.png"
                    alt=""
                  />
                  <div className="absolute bottom-0 left-0 w-full p-2">
                    <div
                      className="bg-black bg-opacity-80 w-full py-4 px-5 rounded-lg"
                      style={{ backdropFilter: "blur(3px)" }}
                    >
                      <h3 className="mb-1 text-lg text-white font-bold leading-snug">
                        Soraya P.
                      </h3>
                      <p className="text-gray-400 font-medium">
                        Fullstack developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/5 p-3">
                <div className="relative max-w-max mx-auto h-full overflow-hidden rounded-3xl">
                  <img
                    className="h-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src="flaro-assets/images/team/team2.png"
                    alt=""
                  />
                  <div className="absolute bottom-0 left-0 w-full p-2">
                    <div
                      className="bg-black bg-opacity-80 w-full py-4 px-5 rounded-lg"
                      style={{ backdropFilter: "blur(3px)" }}
                    >
                      <h3 className="mb-1 text-lg text-white font-bold leading-snug">
                        John Dutch
                      </h3>
                      <p className="text-gray-400 font-medium">
                        Fullstack developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/5 p-3">
                <div className="relative max-w-max mx-auto h-full overflow-hidden rounded-3xl">
                  <img
                    className="h-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src="flaro-assets/images/team/team4.png"
                    alt=""
                  />
                  <div className="absolute bottom-0 left-0 w-full p-2">
                    <div
                      className="bg-black bg-opacity-80 w-full py-4 px-5 rounded-lg"
                      style={{ backdropFilter: "blur(3px)" }}
                    >
                      <h3 className="mb-1 text-lg text-white font-bold leading-snug">
                        Quentin Godé
                      </h3>
                      <p className="text-gray-400 font-medium">
                        Business developer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/5 p-3">
                <div className="relative max-w-max mx-auto h-full overflow-hidden rounded-3xl">
                  <img
                    className="h-full object-cover transform hover:scale-105 transition ease-in-out duration-1000"
                    src="flaro-assets/images/team/team5.png"
                    alt=""
                  />
                  <div className="absolute bottom-0 left-0 w-full p-2">
                    <div
                      className="bg-black bg-opacity-80 w-full py-4 px-5 rounded-lg"
                      style={{ backdropFilter: "blur(3px)" }}
                    >
                      <h3 className="mb-1 text-lg text-white font-bold leading-snug">
                        Fabien Sabatié
                      </h3>
                      <p className="text-gray-400 font-medium">CTO</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-24 pb-28 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="text-center max-w-lg mx-auto">
              <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight">
                Collaborate efficiently with the teams today
              </h2>
              <p className="mb-7 text-lg text-gray-600 font-medium">
                Let us know how we can help you
              </p>
              <a className="mb-11 md:inline-block" href="/contact">
                <button
                  className="py-4 px-6 w-full text-black font-semibold rounded-xl shadow-2xl focus:ring focus:ring-black bg-yellow-300 hover:bg-yellow-400 transition ease-in-out duration-200"
                  type="button"
                >
                  Contact us now
                </button>
              </a>
              <div className="flex flex-wrap items-center justify-center -m-1">
                <div className="w-auto p-1">
                  <div className="flex flex-wrap">
                    <div className="w-auto">
                      <img
                        src="flaro-assets/images/cta/avatar-circle.png"
                        alt=""
                      />
                    </div>
                    <div className="w-auto -ml-3">
                      <img
                        src="flaro-assets/images/cta/avatar-circle2.png"
                        alt=""
                      />
                    </div>
                    <div className="w-auto -ml-3">
                      <img
                        src="flaro-assets/images/cta/avatar-circle3.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="w-auto p-1">
                  <p className="text-gray-600 font-medium">
                    <span>Join other statisfied people</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </>
    </React.Fragment>
  );
}
