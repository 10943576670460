import React from "react";
import { Routes, Route } from "react-router-dom";
import IndexPage from "./pages/Index.js";
import ContactPage from "./pages/Contact.js";
import TeamPage from "./pages/Team.js";
import JobsPage from "./pages/Jobs.js";
import FlowPage from "./pages/Flow.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/jobs" element={<JobsPage />} />
        <Route path="/services" element={<FlowPage />} />
      </Routes>
    </>
  );
}

export default App;
