import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Jobs() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Header />

        <section className="pt-24 pb-36 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <p className="mb-6 text-sm text-yellow-400 text-center font-bold uppercase tracking-px">
              We are hiring
            </p>
            <h2 className="mb-20 text-6xl md:text-8xl xl:text-10xl text-center font-bold font-heading tracking-px-n leading-none">
              Open positions
            </h2>
            <div className="flex flex-wrap -m-1.5 mb-10">
              <div className="w-full p-1.5">
                <a href="/contact">
                  <div className="p-10 h-full bg-blueGray-100 hover:bg-blueGray-200 bg-opacity-70 rounded-xl transition ease-in-out duration-200">
                    <div className="flex flex-wrap justify-between -m-2">
                      <div className="w-auto p-2">
                        <h3 className="mb-4 text-lg font-bold font-heading leading-snug">
                          Fullstack Web Developer
                        </h3>
                        <div className="flex flex-wrap -m-2">
                          <div className="w-auto p-2">
                            <div className="flex flex-wrap items-center -m-1">
                              <div className="w-auto p-1">
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.2426 12.4926C12.6185 13.1168 11.3891 14.3462 10.4137 15.3216C9.63264 16.1026 8.36745 16.1027 7.5864 15.3217C6.62886 14.3641 5.42126 13.1565 4.75736 12.4926C2.41421 10.1495 2.41421 6.35051 4.75736 4.00736C7.10051 1.66421 10.8995 1.66421 13.2426 4.00736C15.5858 6.35051 15.5858 10.1495 13.2426 12.4926Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.25 8.25C11.25 9.49264 10.2426 10.5 9 10.5C7.75736 10.5 6.75 9.49264 6.75 8.25C6.75 7.00736 7.75736 6 9 6C10.2426 6 11.25 7.00736 11.25 8.25Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="w-auto p-1">
                                <p className="font-medium leading-relaxed">
                                  Chiang Mai, Thailand
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="w-auto p-2">
                            <div className="flex flex-wrap items-center -m-1">
                              <div className="w-auto p-1">
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.2426 12.4926C12.6185 13.1168 11.3891 14.3462 10.4137 15.3216C9.63264 16.1026 8.36745 16.1027 7.5864 15.3217C6.62886 14.3641 5.42126 13.1565 4.75736 12.4926C2.41421 10.1495 2.41421 6.35051 4.75736 4.00736C7.10051 1.66421 10.8995 1.66421 13.2426 4.00736C15.5858 6.35051 15.5858 10.1495 13.2426 12.4926Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.25 8.25C11.25 9.49264 10.2426 10.5 9 10.5C7.75736 10.5 6.75 9.49264 6.75 8.25C6.75 7.00736 7.75736 6 9 6C10.2426 6 11.25 7.00736 11.25 8.25Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="w-auto p-1">
                                <p className="font-medium leading-relaxed">
                                  Full-time
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-auto self-end p-2">
                        <div
                          className="flex justify-center items-center text-center font-semibold text-indigo-600 hover:text-indigo-700 leading-normal"
                          href="#"
                        >
                          <span className="mr-2.5 text-black">Talk to us</span>
                          <svg
                            width={17}
                            height={16}
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.83333 3.33337L14.5 8.00004M14.5 8.00004L9.83333 12.6667M14.5 8.00004L2.5 8.00004"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full p-1.5">
                <a href="/contact"></a>
              </div>
              <div className="w-full p-1.5">
                <a href="/contact">
                  <div className="p-10 h-full bg-blueGray-100 hover:bg-blueGray-200 bg-opacity-70 rounded-xl transition ease-in-out duration-200">
                    <div className="flex flex-wrap justify-between -m-2">
                      <div className="w-auto p-2">
                        <h3 className="mb-4 text-lg font-bold font-heading leading-snug">
                          Fullstack Web Developer
                        </h3>
                        <div className="flex flex-wrap -m-2">
                          <div className="w-auto p-2">
                            <div className="flex flex-wrap items-center -m-1">
                              <div className="w-auto p-1">
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.2426 12.4926C12.6185 13.1168 11.3891 14.3462 10.4137 15.3216C9.63264 16.1026 8.36745 16.1027 7.5864 15.3217C6.62886 14.3641 5.42126 13.1565 4.75736 12.4926C2.41421 10.1495 2.41421 6.35051 4.75736 4.00736C7.10051 1.66421 10.8995 1.66421 13.2426 4.00736C15.5858 6.35051 15.5858 10.1495 13.2426 12.4926Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.25 8.25C11.25 9.49264 10.2426 10.5 9 10.5C7.75736 10.5 6.75 9.49264 6.75 8.25C6.75 7.00736 7.75736 6 9 6C10.2426 6 11.25 7.00736 11.25 8.25Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="w-auto p-1">
                                <p className="font-medium leading-relaxed">
                                  Chiang Mai, Thailand
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="w-auto p-2">
                            <div className="flex flex-wrap items-center -m-1">
                              <div className="w-auto p-1">
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.2426 12.4926C12.6185 13.1168 11.3891 14.3462 10.4137 15.3216C9.63264 16.1026 8.36745 16.1027 7.5864 15.3217C6.62886 14.3641 5.42126 13.1565 4.75736 12.4926C2.41421 10.1495 2.41421 6.35051 4.75736 4.00736C7.10051 1.66421 10.8995 1.66421 13.2426 4.00736C15.5858 6.35051 15.5858 10.1495 13.2426 12.4926Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M11.25 8.25C11.25 9.49264 10.2426 10.5 9 10.5C7.75736 10.5 6.75 9.49264 6.75 8.25C6.75 7.00736 7.75736 6 9 6C10.2426 6 11.25 7.00736 11.25 8.25Z"
                                    stroke="#A1A1AA"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="w-auto p-1">
                                <p className="font-medium leading-relaxed">
                                  Full-time
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-auto self-end p-2">
                        <div
                          className="flex justify-center items-center text-center font-semibold text-indigo-600 hover:text-indigo-700 leading-normal"
                          href="#"
                        >
                          <span className="mr-2.5 text-black">Talk to us</span>
                          <svg
                            width={17}
                            height={16}
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.83333 3.33337L14.5 8.00004M14.5 8.00004L9.83333 12.6667M14.5 8.00004L2.5 8.00004"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="w-full p-1.5">
                <a href="/contact"></a>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-28 bg-yellow-400 overflow-hidden">
          <img
            className="absolute bottom-0 right-0"
            src="flaro-assets/images/cta/gradient.svg"
            alt=""
          />
          <div className="relative z-10 container px-4 mx-auto">
            <div className="flex flex-wrap items-center -m-8">
              <div className="w-full md:w-1/2 p-8">
                <h2 className="mb-5 text-6xl md:text-7xl text-black font-bold tracking-px-n leading-tight">
                  Ready to earn money with us?
                </h2>
                <p className="text-black text-opacity-90 font-medium md:max-w-md">
                  We reward partners who help us find clients. Get in touch with
                  us if you no someone we could help.
                </p>
              </div>
              <div className="w-full md:w-1/2 p-8">
                <div className="flex flex-wrap ml-auto md:w-56 -m-2">
                  <a className="w-full p-2" href="/contact">
                    <button
                      className="py-4 px-6 w-full font-medium rounded-xl shadow-6xl focus:ring focus:ring-gray-300 bg-white hover:bg-gray-100 transition ease-in-out duration-200"
                      type="button"
                    >
                      Get in touch
                    </button>
                  </a>
                  <div className="w-full p-2"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </>
    </React.Fragment>
  );
}
