import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Flow() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Header />
        <section className="pt-16 pb-40 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <h2 className="mb-10 text-6xl md:text-8xl xl:text-10xl font-bold font-heading text-center tracking-px-n leading-none">
              What can we build for you today ?
            </h2>
            <h5 className="mb-10 text-2xl md:text-2xl xl:text-2xl text-gray-600 font-bold text-center tracking-px-n leading-none">
              Pick something you need:
            </h5>
            <div className="flex flex-wrap -m-16 md:-m-3">
              <a
                href="/contact"
                className="w-full cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000 md:w-1/3 p-16 md:p-3"
              >
                <div className="px-10 pt-11 text-center bg-gray-100 rounded-4xl">
                  <h3 className="mb-3 text-3xl font-bold font-heading leading-normal">
                    A website
                  </h3>
                  <p className="mb-2 text-gray-600 font-medium leading-relaxed">
                    Explore our custom website development services to establish
                    your digital presence with a bang. Choose this option to get
                    started.
                  </p>
                  <div className="relative w-full h-72 cursor-pointer">
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10"></div>
                    <iframe
                      className="absolute top-0 left-0 w-full h-full bg-opacity-30 object-cover rounded-3xl "
                      src="https://lottie.host/embed/8ff58eeb-24e1-45e9-9bf1-8a2f42e7b1de/AF63xcE8tr.json"
                      alt=""
                    ></iframe>
                  </div>
                </div>
              </a>
              <a
                href="/contact"
                className="w-full cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000 md:w-1/3 p-16 md:p-3"
              >
                <div className="px-10 pt-11 text-center bg-gray-100 rounded-4xl">
                  <h3 className="mb-3 text-3xl font-bold font-heading leading-normal">
                    An online store
                  </h3>
                  <p className="mb-2 text-gray-600 font-medium leading-relaxed">
                    Launch your online store with our comprehensive e-commerce
                    solutions, tailored for businesses aiming to thrive in
                    digital retail.
                  </p>
                  <div className="relative w-full h-72 cursor-pointer">
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10"></div>
                    <iframe
                      className="absolute top-0 left-0 w-full h-full bg-opacity-30 object-cover rounded-3xl "
                      src="https://lottie.host/embed/455ed725-f8c1-43d9-bc5e-cc6321dad1fe/XtIYiA1hnz.json"
                      alt=""
                    ></iframe>
                  </div>
                </div>
              </a>
              <a
                href="/contact"
                className="w-full cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000 md:w-1/3 p-16 md:p-3"
              >
                <div className="px-10 pt-11 text-center bg-gray-100 rounded-4xl">
                  <h3 className="mb-3 font-bold font-heading leading-normal text-3xl">
                    A mobile application
                  </h3>
                  <p className="mb-2 text-gray-600 font-medium leading-relaxed">
                    Bring your app idea to life with our mobile application
                    development services. Perfect for innovators looking to
                    disrupt the market.
                  </p>
                  <div className="relative w-full h-72 cursor-pointer">
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10"></div>
                    <iframe
                      className="absolute top-0 left-0 w-full h-full bg-opacity-30 object-cover rounded-3xl "
                      src="https://lottie.host/embed/66113635-e01a-4791-abcb-d698f59894e8/KBYqdnTEf0.json"
                      alt=""
                    ></iframe>
                  </div>
                </div>
              </a>
              <a
                href="/contact"
                className="w-full cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000 md:w-1/3 p-16 md:p-3"
              >
                <div className="px-10 pt-11 text-center bg-gray-100 rounded-4xl">
                  <h3 className="mb-3 text-3xl font-bold font-heading leading-normal">
                    A project with AI
                  </h3>
                  <p className=" text-gray-600 font-medium leading-relaxed">
                    Dive into the future with our AI project development. Ideal
                    for those looking to leverage artificial intelligence for
                    their business.
                  </p>
                  <div className="relative w-full h-72 cursor-pointer">
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10"></div>
                    <iframe
                      className="absolute top-0 left-0 w-full h-full bg-opacity-30 object-cover rounded-3xl "
                      src="https://lottie.host/embed/d9044dbc-267f-4658-b3f3-13c2bb7275f4/MtL2J39DBr.json"
                      alt=""
                    ></iframe>
                  </div>
                </div>
              </a>
              <a
                href="/contact"
                className="w-full cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000 md:w-1/3 p-16 md:p-3"
              >
                <div className="px-10 pt-11 text-center bg-gray-100 rounded-4xl">
                  <h3 className="mb-3 text-3xl font-bold font-heading leading-normal">
                    Logistics & Data visualisation
                  </h3>
                  <p className=" text-gray-600 font-medium leading-relaxed">
                    Streamline your operations with our logistics and data
                    visualization solutions, designed for businesses seeking
                    efficiency and insight.
                  </p>
                  <div className="relative w-full h-72 cursor-pointer">
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10"></div>
                    <iframe
                      className="absolute top-0 left-0 w-full h-full bg-opacity-30 object-cover rounded-3xl "
                      src="https://lottie.host/embed/a6f8f1f1-ed67-47d7-983c-5f65b75d5b24/kZvmQk9orY.json"
                      alt=""
                    />
                  </div>
                </div>
              </a>
              <a
                href="/contact"
                className="w-full cursor-pointer transform hover:-translate-y-3 transition ease-in-out duration-1000 md:w-1/3 p-16 md:p-3"
              >
                <div className="px-10 pt-11 text-center bg-gray-100 rounded-4xl">
                  <h3 className="mb-3 font-bold font-heading leading-normal text-3xl">
                    Anything else
                  </h3>
                  <p className=" text-gray-600 font-medium leading-relaxed">
                    If your needs don't fit the mold, we're here to help. Tell
                    us about your unique project, and let's create something
                    exceptional together.
                  </p>
                  <div className="relative w-full h-72 cursor-pointer">
                    <div className="absolute top-0 left-0 right-0 bottom-0 z-10"></div>
                    <iframe
                      className="absolute top-0 left-0 w-full h-full bg-opacity-30 object-cover rounded-3xl "
                      src="https://lottie.host/embed/65013a98-05e9-426a-a706-55c075fbe7fa/cJVVpED38k.json"
                      alt=""
                    ></iframe>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </>
    </React.Fragment>
  );
}
