import React, { useLayoutEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  const isMounted = useRef(false);

  useLayoutEffect(() => {
    // Scene setup
    if (isMounted.current) return;
    const el = document.body.querySelector("#scene");

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    // Renderer with transparent background
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0); // the default clear color is black with full opacity (0), making it fully transparent

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 5);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 0).normalize();
    scene.add(directionalLight);
    let mixer;
    // GLTF Loader for GLB model
    const loader = new GLTFLoader();
    loader.load(
      "/assets/robot.glb", // Path to your GLB model
      function (gltf) {
        scene.add(gltf.scene);
        // Check if there are animations in the loaded model
        if (gltf.animations && gltf.animations.length) {
          // Create an AnimationMixer, and bind it to the model's scene
          mixer = new THREE.AnimationMixer(gltf.scene);

          // Access the first animation clip
          const action = mixer.clipAction(gltf.animations[0]);

          // Play the animation
          action.play();
        }
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    // OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // An optional feature that adds inertia to camera movement, making it feel smoother
    controls.dampingFactor = 0.05;
    // Camera position
    camera.translateY(45);
    camera.translateX(-50);
    controls.target.set(0, 10, -30);
    // Lighting, scene, and GLB model loading code remains the same

    // Animation loop
    function animate() {
      requestAnimationFrame(animate);
      // Update the animation mixer on each frame
      if (mixer) mixer.update(0.01); // The argument t
      controls.update(); // Only required if controls.enableDamping or controls.autoRotate are set to true
      renderer.render(scene, camera);
    }
    renderer.domElement.style =
      "position: absolute; top:0px; right:100px; width:80%; height:80%";
    document.body.appendChild(renderer.domElement);
    animate();
    isMounted.current = true;
  }, []);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Header />
        <section>
          <div className="overflow-hidden pb-16">
            <div className="container px-4 mx-auto">
              <div className="my-16 relative bg-indigo-50 overflow-hidden rounded-3xl">
                <img
                  className="absolute left-0 bottom-0"
                  src="flaro-assets/images/headers/gradient3.svg"
                  alt=""
                />
                <div
                  id="scene"
                  className="relative z-20 flex flex-wrap items-center -m-8 pointer-events-none"
                >
                  <div className="w-full lg:w-1/2 p-20 lg:pr-0 lg:pl-28 lg:py-28 pointer-events-auto">
                    <h2
                      className="mb-7 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none"
                      contentEditable="false"
                    >
                      We bring your software to life.
                    </h2>
                    <p
                      className="mb-10 text-lg text-gray-900 font-medium"
                      contentEditable="false"
                    >
                      Because we have the best developers for your software
                      needs.
                    </p>
                    <a className="mb-6 md:inline-block" href="/services">
                      <button
                        className="py-4 px-6 w-full text-black font-semibold  rounded-xl focus:ring focus:ring-black bg-yellow-300 hover:bg-yellow-400 transition ease-in-out duration-200"
                        type="button"
                      >
                        Explore our services
                      </button>
                    </a>
                    <a href="/contact">
                      <ul className="pt-8">
                        <li
                          className="inline-flex text-sm text-gray-500 font-medium"
                          contentEditable="false"
                        >
                          Get in touch
                        </li>
                        <li className="inline-flex text-black px-1">✉</li>
                        <li
                          className="inline-flex text-sm text-gray-500 font-medium"
                          contentEditable="false"
                        >
                          Learn with us
                        </li>
                        <li className="inline-flex text-black px-1">🎓</li>
                        <li
                          className="inline-flex text-sm text-gray-500 font-medium"
                          contentEditable="false"
                        >
                          Earn with us
                        </li>
                        <li className="inline-flex text-black px-1">😎</li>
                      </ul>
                    </a>
                  </div>
                </div>
              </div>
              <p className="mb-9  text-center mb-4 text-xl font-semibold leading-normal">
                They worked with us and they loved it !
              </p>
              <div className="flex flex-wrap justify-center items-center -m-8">
                <a
                  href="https://mext.app"
                  target="_blank"
                  className="w-auto grayscale hover:filter-none transform hover:-translate-y-3 transition duration-1000 p-8"
                >
                  <img src="images/mext.jpg" alt="" className="w-24" />
                </a>
                <a
                  href="https://madbox.io"
                  target="_blank"
                  className="w-auto grayscale hover:filter-none transform hover:-translate-y-3 transition duration-1000 p-8"
                >
                  <img src="images/madbox.jpg" alt="" className="w-36" />
                </a>
                <a
                  href="https://www.zodiac-nautic.com/"
                  target="_blank"
                  className="w-auto grayscale hover:filter-none transform hover:-translate-y-3 transition duration-1000 p-8"
                >
                  <img
                    src="images/logo-zodiac-web.png"
                    alt=""
                    className="w-32"
                  />
                </a>
                <a
                  href="https://42.fr"
                  target="_blank"
                  className="w-auto grayscale hover:filter-none transform hover:-translate-y-3 transition duration-1000 p-8"
                >
                  <img src="images/42-Logo-svg.png" alt="" className="w-12" />
                </a>
                <a
                  href="https://bniterreatlantique.fr/fr/index"
                  target="_blank"
                  className="w-auto grayscale hover:filter-none transform hover:-translate-y-3 transition duration-1000 p-8"
                >
                  <img
                    src="images/5fa7bbd8bfbb1c002e26449e.png"
                    alt=""
                    className="w-24"
                  />
                </a>
                <a
                  href="https://www.creditmutuel.fr/"
                  target="_blank"
                  className="w-auto grayscale hover:filter-none transform hover:-translate-y-3 transition duration-1000 p-8"
                >
                  <img
                    src="images/logo-CreditMutuelAM-600x300.png"
                    alt=""
                    className="w-32"
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="py-32 bg-white overflow-hidden">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 p-8">
                <div className="md:max-w-lg">
                  <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading tracking-px-n leading-tight">
                    We do things differently
                  </h2>
                  <p className="text-gray-600 font-medium leading-relaxed">
                    Goldenpupa is a registered company. But it's also a free
                    school here in Chiang Mai, Thailand. Students come in and
                    learn on their own. We provide all the resources for them to
                    educate themselves to software development. They validate
                    projects, progress in levels, and validate their skills. The
                    final exam constitues our technical test. Students who pass
                    it are hired and start working with us.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-8">
                <div className="flex flex-wrap">
                  <div className="w-full">
                    <div className="flex flex-wrap -m-7">
                      <div className="w-auto p-7">
                        <div className="relative w-11 h-11 border border-blueGray-200 rounded-full">
                          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <svg
                              width={23}
                              height={23}
                              viewBox="0 0 23 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.4375 7.83333V19.75M11.4375 7.83333C11.4375 7.83333 11.4375 6.46495 11.4375 6C11.4375 4.98748 12.2583 4.16667 13.2708 4.16667C14.2834 4.16667 15.1042 4.98748 15.1042 6C15.1042 7.01252 14.2834 7.83333 13.2708 7.83333C12.7233 7.83333 11.4375 7.83333 11.4375 7.83333ZM11.4375 7.83333C11.4375 7.83333 11.4375 6.05767 11.4375 5.54167C11.4375 4.27601 10.4115 3.25 9.14583 3.25C7.88018 3.25 6.85417 4.27601 6.85417 5.54167C6.85417 6.80732 7.88018 7.83333 9.14583 7.83333C9.89548 7.83333 11.4375 7.83333 11.4375 7.83333ZM5.02083 11.5H17.8542M5.02083 11.5C4.00831 11.5 3.1875 10.6792 3.1875 9.66667C3.1875 8.65414 4.00831 7.83333 5.02083 7.83333H17.8542C18.8667 7.83333 19.6875 8.65414 19.6875 9.66667C19.6875 10.6792 18.8667 11.5 17.8542 11.5M5.02083 11.5L5.02083 17.9167C5.02083 18.9292 5.84164 19.75 6.85417 19.75H16.0208C17.0334 19.75 17.8542 18.9292 17.8542 17.9167V11.5"
                                stroke="#d8b810"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="w-px h-28 bg-blueGray-200 mx-auto" />
                      </div>
                      <div className="flex-1 p-7">
                        <div className="md:max-w-sm pb-8">
                          <h3
                            className="mb-4 text-xl font-semibold leading-normal"
                            contentEditable="false"
                          >
                            Educate the local people, for free. 🎓
                          </h3>
                          <p className="text-gray-600 font-medium leading-relaxed">
                            We wanted to make sure that our developers receive
                            the best education. So we built a free school. When
                            they pass their final exam, they are ready to work
                            with us, and you.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex flex-wrap -m-7">
                      <div className="w-auto p-7">
                        <div className="relative w-11 h-11 border border-blueGray-200 rounded-full">
                          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <svg
                              width={23}
                              height={23}
                              viewBox="0 0 23 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.0208 8.75001V6.91668C16.0208 5.90415 15.2 5.08334 14.1875 5.08334H5.02083C4.00831 5.08334 3.1875 5.90415 3.1875 6.91668V12.4167C3.1875 13.4292 4.00831 14.25 5.02083 14.25H6.85417M8.6875 17.9167H17.8542C18.8667 17.9167 19.6875 17.0959 19.6875 16.0833V10.5833C19.6875 9.57082 18.8667 8.75001 17.8542 8.75001H8.6875C7.67498 8.75001 6.85417 9.57082 6.85417 10.5833V16.0833C6.85417 17.0959 7.67498 17.9167 8.6875 17.9167ZM15.1042 13.3333C15.1042 14.3459 14.2834 15.1667 13.2708 15.1667C12.2583 15.1667 11.4375 14.3459 11.4375 13.3333C11.4375 12.3208 12.2583 11.5 13.2708 11.5C14.2834 11.5 15.1042 12.3208 15.1042 13.3333Z"
                                stroke="#d8b810"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="w-px h-36 bg-blueGray-200 mx-auto" />
                      </div>
                      <div className="flex-1 p-7">
                        <div className="md:max-w-sm pb-8">
                          <h3
                            className="mb-4 text-xl font-semibold leading-normal"
                            contentEditable="false"
                          >
                            Pay Better 💪
                          </h3>
                          <p className="text-gray-600 font-medium leading-relaxed">
                            Here in Thailand, the average salary is 250$. We pay
                            our developers a lot better to help them build their
                            life, and support the local people.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="flex flex-wrap -m-7">
                      <div className="w-auto p-7">
                        <div className="relative w-11 h-11 border border-blueGray-200 rounded-full">
                          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <svg
                              width={23}
                              height={23}
                              viewBox="0 0 23 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.6233 15.7688C15.7915 16.6005 14.0806 18.3115 12.8512 19.5408C12.0702 20.3219 10.8051 20.3215 10.024 19.5404C8.81645 18.3329 7.13832 16.6547 6.25238 15.7688C3.38853 12.9049 3.38853 8.26173 6.25238 5.39788C9.11622 2.53404 13.7594 2.53404 16.6233 5.39788C19.4871 8.26173 19.4871 12.9049 16.6233 15.7688Z"
                                stroke="#d8b810"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.1878 10.5833C14.1878 12.1021 12.9566 13.3333 11.4378 13.3333C9.91904 13.3333 8.68783 12.1021 8.68783 10.5833C8.68783 9.06455 9.91904 7.83333 11.4378 7.83333C12.9566 7.83333 14.1878 9.06455 14.1878 10.5833Z"
                                stroke="#d8b810"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 p-7">
                        <div className="md:max-w-sm pb-8">
                          <h3
                            className="mb-4 text-xl font-semibold leading-normal flex justify-start gap-2 items-baseline"
                            contentEditable="true"
                          >
                            <span>Think Better 🧠</span>
                          </h3>
                          <p className="text-gray-600 font-medium leading-relaxed">
                            We make sure our developers at not only good, but
                            excellent at what they do. Our cursus tailors their
                            skillset to your business needs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative pt-28 pb-24 bg-white overflow-hidden">
          <img
            className="absolute left-1/2 top-0 transform -translate-x-1/2"
            src="flaro-assets/images/how-it-works/gradient2.svg"
            alt=""
          />
          <div className="relative z-10 container px-4 mx-auto">
            <p className="mb-6 text-sm text-yellow-400 text-center font-semibold uppercase tracking-px">
              HOW GOLDENPUPA WORKS
            </p>
            <h2 className="mb-20 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight">
              Make software easy for your business
            </h2>
            <div className="flex flex-wrap -m-8">
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={22}
                        height={22}
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.75 9.16658H19.25M6.41667 13.7499H7.33333M11 13.7499H11.9167M5.5 17.4166H16.5C18.0188 17.4166 19.25 16.1854 19.25 14.6666V7.33325C19.25 5.81447 18.0188 4.58325 16.5 4.58325H5.5C3.98122 4.58325 2.75 5.81447 2.75 7.33325V14.6666C2.75 16.1854 3.98122 17.4166 5.5 17.4166Z"
                          stroke="#d8b810"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200" />
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      Get in touch with us&nbsp; 😍
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      Let's discuss your exact needs, it's free. And we would
                      LOVE to hear more about your story, and what you want to
                      accomplish.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 8V16M12 11V16M8 14V16M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z"
                          stroke="#d8b810"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200" />
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      We study your needs 🤓
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      You might need a e-commerce website, a landing page, an
                      ERP, a plugin, or someone to talk to ? We got you. We'll
                      find the best software solution for your specific needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-white w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={19}
                        height={16}
                        viewBox="0 0 19 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.66717 2.54545V10.0455C8.66717 10.7841 8.52335 11.489 8.23571 12.1602C7.94807 12.8313 7.55922 13.4119 7.06916 13.902C6.5791 14.392 5.99849 14.7809 5.32733 15.0685C4.65616 15.3562 3.95126 15.5 3.21262 15.5H2.53081C2.34615 15.5 2.18635 15.4325 2.0514 15.2976C1.91646 15.1626 1.84899 15.0028 1.84899 14.8182V13.4545C1.84899 13.2699 1.91646 13.1101 2.0514 12.9751C2.18635 12.8402 2.34615 12.7727 2.53081 12.7727H3.21262C3.96547 12.7727 4.60822 12.5064 5.14089 11.9737C5.67356 11.4411 5.9399 10.7983 5.9399 10.0455V9.70455C5.9399 9.42046 5.84047 9.17898 5.6416 8.98011C5.44274 8.78125 5.20126 8.68182 4.91717 8.68182H2.53081C1.96262 8.68182 1.47967 8.48295 1.08194 8.08523C0.684215 7.6875 0.485352 7.20455 0.485352 6.63636V2.54545C0.485352 1.97727 0.684215 1.49432 1.08194 1.09659C1.47967 0.698864 1.96262 0.5 2.53081 0.5H6.62172C7.1899 0.5 7.67285 0.698864 8.07058 1.09659C8.46831 1.49432 8.66717 1.97727 8.66717 2.54545ZM18.2126 2.54545V10.0455C18.2126 10.7841 18.0688 11.489 17.7812 12.1602C17.4935 12.8313 17.1047 13.4119 16.6146 13.902C16.1246 14.392 15.5439 14.7809 14.8728 15.0685C14.2016 15.3562 13.4967 15.5 12.7581 15.5H12.0763C11.8916 15.5 11.7318 15.4325 11.5969 15.2976C11.4619 15.1626 11.3944 15.0028 11.3944 14.8182V13.4545C11.3944 13.2699 11.4619 13.1101 11.5969 12.9751C11.7318 12.8402 11.8916 12.7727 12.0763 12.7727H12.7581C13.5109 12.7727 14.1537 12.5064 14.6863 11.9737C15.219 11.4411 15.4854 10.7983 15.4854 10.0455V9.70455C15.4854 9.42046 15.3859 9.17898 15.1871 8.98011C14.9882 8.78125 14.7467 8.68182 14.4626 8.68182H12.0763C11.5081 8.68182 11.0251 8.48295 10.6274 8.08523C10.2297 7.6875 10.0308 7.20455 10.0308 6.63636V2.54545C10.0308 1.97727 10.2297 1.49432 10.6274 1.09659C11.0251 0.698864 11.5081 0.5 12.0763 0.5H16.1672C16.7354 0.5 17.2183 0.698864 17.616 1.09659C18.0138 1.49432 18.2126 1.97727 18.2126 2.54545Z"
                          fill="#d8b810"
                        />
                      </svg>
                    </div>
                    <div className="hidden lg:block absolute left-12 top-1/2 transform -translate-y-1/2 w-96 h-px bg-gray-200" />
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      Get a Quotation&nbsp; 🧐
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      We offer a VERY competitive offer. For the price of one
                      developer in Europe/US, we can hire four people in
                      Thailand. So it goes a LOT faster ⚡
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 p-8">
                <div className="text-center">
                  <div className="relative z-10 bg-yellow-400 w-12 h-12 mb-8 mx-auto border border-blueGray-200 rounded-full">
                    <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        width={22}
                        height={16}
                        viewBox="0 0 22 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20.5734 0.93934C21.1591 1.52513 21.1591 2.47487 20.5734 3.06066L8.57336 15.0607C7.98757 15.6464 7.03782 15.6464 6.45204 15.0607L0.452035 9.06066C-0.133751 8.47487 -0.133751 7.52513 0.452035 6.93934C1.03782 6.35355 1.98757 6.35355 2.57336 6.93934L7.5127 11.8787L18.452 0.93934C19.0378 0.353553 19.9876 0.353553 20.5734 0.93934Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="md:max-w-xs mx-auto">
                    <h3 className="mb-4 font-heading text-xl font-bold font-heading leading-normal">
                      Your project is live&nbsp;🥳
                    </h3>
                    <p className="text-gray-600 font-medium leading-relaxed">
                      You get full access to YOUR project. We adapt our
                      technologies to what YOU can manage, so that you don't
                      need us anymore.&nbsp;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-32 white overflow-hidden">
          <img
            className="hidden absolute bottom-0 right-0"
            src="flaro-assets/images/cta/gradient2.svg"
            alt=""
          />
          <div className="relative z-10 container px-4 mx-auto">
            <div className="flex flex-wrap lg:items-center -m-8">
              <div className="w-full md:w-1/2 p-8">
                <img
                  className="mx-auto transform hover:translate-x-16 transition ease-in-out duration-1000"
                  src="flaro-assets/images/cta/people.png"
                  alt=""
                />
              </div>
              <div className="w-full md:w-1/2 p-8">
                <div className="relative md:max-w-xl">
                  <img
                    className="absolute top-0 right-0"
                    src="flaro-assets/images/cta/gradient3.svg"
                    alt=""
                  />
                  <div className="relative z-10">
                    <h2
                      className="mb-11 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none"
                      contentEditable="false"
                    >
                      Earn money with us.
                    </h2>
                    <div className="md:max-w-lg">
                      <div className="flex flex-wrap -m-2 mb-3">
                        <div className="w-auto p-2">
                          <svg
                            className="mt-1"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx={10} cy={10} r={10} fill="#d8b810" />
                            <path
                              d="M6 10.6667L8.66667 13.3333L14 8"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <p
                            className="font-medium leading-relaxed"
                            contentEditable="false"
                          >
                            We're inclusive, just be you and we'll love it.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap -m-2 mb-3">
                        <div className="w-auto p-2">
                          <svg
                            className="mt-1"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx={10} cy={10} r={10} fill="#d8b810" />
                            <path
                              d="M6 10.6667L8.66667 13.3333L14 8"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <p
                            className="font-medium leading-relaxed"
                            contentEditable="false"
                          >
                            If you need a software solution for your business.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap -m-2 mb-3">
                        <div className="w-auto p-2">
                          <svg
                            className="mt-1"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx={10} cy={10} r={10} fill="#d8b810" />
                            <path
                              d="M6 10.6667L8.66667 13.3333L14 8"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <p
                            className="font-medium leading-relaxed"
                            contentEditable="false"
                          >
                            If you want to learn a new skill and study with us.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-wrap -m-2 mb-3">
                        <div className="w-auto p-2">
                          <svg
                            className="mt-1"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx={10} cy={10} r={10} fill="#d8b810" />
                            <path
                              d="M6 10.6667L8.66667 13.3333L14 8"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="flex-1 p-2">
                          <p
                            className="font-medium leading-relaxed"
                            contentEditable="false"
                          >
                            If you want to earn money with us, and help us find
                            clients.
                          </p>
                        </div>
                      </div>
                    </div>
                    <a className="mb-4 md:max-w-md" href="/contact">
                      <button
                        className="py-4 px-5 w-full text-sm text-black  uppercase tracking-px font-bold rounded-xl focus:ring focus:ring-black bg-yellow-300 hover:bg-yellow-400 transition ease-in-out duration-200"
                        type="button"
                        contentEditable="false"
                      >
                        Send us a message
                      </button>
                    </a>
                    <div className="flex flex-wrap items-center">
                      <div className="w-auto p-1">
                        <svg
                          width={18}
                          height={18}
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 11.25V12.75M4.5 15.75H13.5C14.3284 15.75 15 15.0784 15 14.25V9.75C15 8.92157 14.3284 8.25 13.5 8.25H4.5C3.67157 8.25 3 8.92157 3 9.75V14.25C3 15.0784 3.67157 15.75 4.5 15.75ZM12 8.25V5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25V8.25H12Z"
                            stroke="#A1A1AA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                      <div className="flex-1 p-1">
                        <p
                          className="text-sm text-gray-500 font-medium"
                          contentEditable="true"
                        >
                          We would never share or sell your email address
                          publicly. Good developers are attached to the people's
                          right to privacy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer></Footer>
      </>
    </React.Fragment>
  );
}
