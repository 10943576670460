export function Footer() {
  return (
    <section className="relative pt-24 pb-28 bg-white overflow-hidden">
      <img
        className="absolute right-0 top-0"
        src="flaro-assets/images/footers/gradient2.svg"
        alt=""
      />
      <div className="relative z-10 container px-4 mx-auto">
        <div className="flex flex-wrap justify-between -m-8">
          <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
            <a className="inline-block" href="#">
              <img src="images/goldenpupas.jpg" alt="" />
            </a>
          </div>
          <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
            <h3 className="mb-6 font-semibold leading-normal">Services</h3>
            <ul>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/services#ecom"
                >
                  E-commerce
                </a>
              </li>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/services#web"
                >
                  Landing pages
                </a>
              </li>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/services#ecom"
                >
                  Social media
                </a>
              </li>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/services#erp"
                >
                  ERP
                </a>
              </li>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/services#app"
                >
                  Applications
                </a>
              </li>
              <li>
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/services#web"
                >
                  Custom solutions
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
            <h3 className="mb-6 font-semibold leading-normal">Developers</h3>
            <ul>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/jobs"
                >
                  Jobs
                </a>
              </li>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="#"
                >
                  Learn with us
                </a>
              </li>
              <li className="mb-3.5"></li>
              <li></li>
            </ul>
          </div>
          <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
            <h3 className="mb-6 font-semibold leading-normal">Resources</h3>
            <ul>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/team"
                  contentEditable="false"
                >
                  Our team
                </a>
              </li>

              <li className="mb-3.5"></li>
              <li className="mb-3.5">
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/jobs"
                >
                  Careers/Team
                </a>
              </li>
              <li>
                <a
                  className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                  href="/contact"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
